@font-face {
  font-family: 'Futura';
  src: url('./assets/font/futura.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

#slider {
  margin: 0 auto;
  display: block;
  overflow: hidden;
/*  width: 200px;*/
  height: 100px;
}

@media screen and (min-width: 400px) {
  #slider {
	/* width: 400px;*/
    height: 200px;
  }
}

@media (max-width: 600px) {
	#slider {
		height: 400px;
	}
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 30px;
  }
  
  #slider {
	/* width: 100%; */
    height: 100%;
  }
}

img {
/*  width: 100%;*/
  height: 100%;
}

#slider figure {
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    animation: 20s slider infinite;
}

#slider figure .image {
  width: 20%;
  float: left;
}

.figure-container {
    z-index: 1; /* Ensure the slider is above other elements in the stacking context */
  }

/* Calculado para 5 items */
/*@keyframes slider {
    0% { left: 0; }
    20% { left: 0; }
    25% { left: -100%; }
    45% { left: -100%; }
    50% { left: -200%; }
    70% { left: -200%; }
    75% { left: -300%; }
    95% { left: -300%; }
    100% { left: -400%; }
}*/

@keyframes slider {
  0%, 100% {
    left: 0;
  }
  25%, 50% {
    left: -100%;
  }
  75% {
    left: -200%;
  }
  100% {
    left: -200%; /* Keep the final state to avoid a white flash */
  }
}


.second {
  position: relative;
  background-image:url(https://res.cloudinary.com/dfs540rt8/image/upload/v1703351108/recreate-africa/2_mreqra.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.second::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}
.second {
  flex-direction: row;
  gap: 1.6em;
}

/* swiper slider on about page */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}